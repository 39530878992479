/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.display-flex-end{
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
}
.display-flex-center{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.display-flex-space-between{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.display-flex-start-column{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pos-relative{
  position: relative !important;
}
.pos-absolute{
  position: absolute !important;
}
.outline-none{
    outline: none !important
}
.white-color{
    color: #fff;
}
.cursor-pointer{
  cursor: pointer;
}
.full-height{
    height: 100%;
}
.m-t-1rem{
  margin-top: 1rem;
}
.m-b-1rem{
  margin-bottom: 1rem;
}
.m-2rem{
    margin: 2rem;
}
.m-t-55{
  margin-top: 55px !important;
}
.m-40{
  margin: 40px !important;
}
.not-available-product-badge {
  right: 0;
  top: 0;
  z-index: 999;
}
.margin-0{
  margin: 0 !important;
}
.full-width{
    width: 100%;
}
.text-center{
    text-align: center;
}
.d-block{
    display: block !important;
}
.d-none{
  display: none;
}
.display-inline-block {
  display: inline-block !important;
}
.m-b10{
    margin-bottom: 10px;
}
.m-5{
    margin: 5px !important;
}
.m-0{
  margin: 0 !important;
}
.px-5{
  padding: 0 5px !important;
}
.ion-no-background{
  --background: none;
}
.error-toast {
    --background: #ff6357;
    --color: white;
  }
  
  .warning-toast {
    --background: #fdc26a;
  }
  
  .success-toast {
    --background: #49d64f;
    --color: white;
  }
  .ion-modal{
    --width: 100% !important;
    --height: 100% !important;
    //display: block !important;
  }
  
  .m-l-1rem{
    margin-left: 1rem !important;
  }

  .text-right {
  text-align: right;
}
.py-5{
  padding: 5px 0;
}
.m-t-10 {
  margin-top: 10px;
}
  .p-r-25{
    padding-right: 25px;
  }
  .flex-align-center{
    display: flex !important;
    align-items: center;
  }
  .display-block {
    display: block !important;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 24px !important;
}

  .purple-twitch-gradient{
    --background: linear-gradient(to left, #6441a5, #2a0845)!important;
  }
  
.danger-btn {
    --background: #F44336;
    --color: white;
  }

  .delete-btn{
    background-color: #f04141;
    color:#fff;
}
.warn-btn{
  background-color: #fdc26a;
  color:#fff;
}

.validation-btn{
    background-color: #10dc60;
    color:#fff;

}
.loader-section{
  background-color: #fff;
}
.loader-section img {
  width: 50px;
}

image-cropper .ngx-ic-cropper,image-cropper .ngx-ic-overlay{
  outline: none !important;
}

.pac-container{
  z-index: 100000 !important;
}
ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}
.pay-store-modal{
  --width: 100%;
  --height: 100%;
}
.pay-store-modal app-pay-store,.pay-store-modal app-activate-free-delivery,
.pay-store-modal app-edit-categories,
.pay-store-modal app-activate-free-delivery-by-category,.add-funds-operation app-cash-register-form,
.add-publishing-house app-publishing-house-form, .add-coupon app-coupon-form, .payments-history app-payments-history{
  justify-content: unset;
  overflow: auto;
}
.order-payment-modal{
  --height: 150px;
  --width: 300px;
}
.pay-store-modal ~ .cdk-overlay-container{
  z-index: 20002;
}
.add-funds-operation ~ .cdk-overlay-container{
  z-index: 20007;
}
.order-modal ~ .cdk-overlay-container{
  z-index: 100000;
}
.amount-caisse .mat-form-field-infix {
  display: inherit;
}
.purple-amethyst-gradient{
  background:  linear-gradient(to right, #9d50bb, #6e48aa);
}

.purple-amin-gradient{
  background: linear-gradient(to right, #8e2de2, #4a00e0);
} 
.purple-moon-purple-gradient{
  background: linear-gradient(to left, #4e54c8, #8f94fb);
} 
.purple-purplin-gradient{
 background: linear-gradient(to left, #6a3093, #a044ff);
}
.deep-purple-gradient{
  background: linear-gradient(to left, #673ab7, #512da8);
 }
 .ionic-deep-purple-gradient{
  --background: linear-gradient(to left, #673ab7, #512da8);
 }
 .yellow-learning-leading-gradient{
  background: linear-gradient(to left, #f7971e, #ffd200);
 }
 .yellow-snapchat-gradient{
  background: linear-gradient(to left, #fffc00, #ffffff);;
 }

button:focus{
    outline: none !important;
}
.pre-wrap{
  white-space: pre-wrap !important;
}